import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './TabNav.css';
import style from './TabNav.module.css'
import Translator from '../I18n/Translator';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yellow } from '@mui/material/colors';
import { LinguagemContext } from '../../common/context/LinguagemContext';

//receba por parametros theme=theme link_gripe="" link_zika= ""
const TabNav = ({theme, currentPage, link_gripe, link_zika, url_atual}) => {

    
    const { t } = useTranslation();
    
    const {language} = useContext(LinguagemContext);

    const rectangleCount = 10; // Número de retângulos
    /*const redTones = [
        "#990000",
        "#CC0000",
        "#FF0000",
        "#FF3333",
        "#FF6666",
        "#FF9999",
        "#FFCCCC",
        "#FF9999",
        "#FF6666",
        "#FF3333"
    ];*/

    const redTones = [
        "#FFCCCC",
        "#FF9999",
        "#FF9999",
        "#FF6666",
        "#FF6666",
        "#FF3333",
        "#FF3333",
        "#FF0000",
        "#CC0000",
        "#990000"
    ];

    const rectangles = [];
    for (let i = 0; i < rectangleCount; i++) {
        const style = {
            backgroundColor: redTones[i],
            maxHeight: "5px",
            minHeight: "2px",
            maxWidth: "10%",
            minWidth: "10%",
            flex: "1"
        };
        rectangles.push(<div key={i} style={style}></div>);
    }

    const pages = {
        "Painel Completo": "/mapa",
        "Metagenomica": "/metagenomic",
        "IBP": "/ibp",
        "Clima": "/climate",
        "Web Based": "/webbased"
    };

    const pages_translated = {
        "Painel Completo": "links_menu.mapa",
        "Metagenomica": "links_menu.metagenomic",
        "IBP": "links_menu.ibp",
        "Clima": "links_menu.climate",
        "Web Based": "links_menu.webbased"
    };

    const corDesativado = "rgba(160, 12, 12, 0.397)";
    const corAtivado = "#990000";
    const isResumido = currentPage === "Painel Resumido";
    /*const currentPage_ = url_atual === "/maparesumido" ? "Painel Completo" : currentPage;*/

    return (
        <div>
            <div className='nav_abas' style={{backgroundColor: theme === "dark" ? "black" : "white"}}>
                <div className='linha_sup_menu'>
                    <div className="menu">
                        <button href="#" className="menu-button"><Translator path="dashboard.gripe" /></button> 
                    </div>   
                    <div className="menu2">
                        <a href={link_zika} style={{ textDecoration: 'none' }}>
                            <button className="menu-button2" style={{backgroundColor: theme === "dark" ? "#363636" : "#d6d6d6"}}><Translator path="dashboard.arbo" /> <img class="arrow ocultar" src="imagens/seta-para-expandir.png"/></button>
                        </a>
                    </div>
                    <div className='div_help'>
                        <a href={language === "pt" ? "Dados/help.pdf" : "Dados/help_ingles.pdf"}  target='_blank' rel='noopener noreferre'>
                            <img src="imagens/icones/help.png" className='img_help' alt="Help Icon"/>
                        </a>
                    </div>
                </div>
                <div style={{minHeight: "5px", minWidth:"100%", display:"flex"}}>{rectangles}</div> 
                <div className="menu3" style={{backgroundColor: !isResumido ? corAtivado : corDesativado}}>
                    <Link to={isResumido ? "/mapa" : "#"} style={{ textDecoration: 'none' }}>
                        <button href={isResumido ? "/mapa" : "#"} className={!isResumido ? "submenu-button active_button" : "submenu-button deactive_button"} >{isResumido ? t(pages_translated["Painel Completo"]) : t(pages_translated[currentPage])}<img class="arrow" src="imagens/seta-para-expandir.png"/></button>
                    </Link>
                    <div className="submenu">
                        {Object.entries(pages)
                            .filter(([name]) => (name !== (isResumido ? "Painel Completo" : currentPage)) ) // Exclui a página atual
                            .map(([name, path]) => (
                                <a href={path} key={name}>{t(pages_translated[name])}</a>
                            ))}
                        {/*<a href="/maparesumido"><Translator path="dashboard.title_resumo" /></a>*/}
                        {/*<a href="/metagenomic">Metagenomica</a>
                        <a href="/ibp">IBP</a>
                        <a href="/climate">Clima</a>
                        <a href="/webbased">Web Based</a> */}
                    </div>   
                </div> 
                <div className="menu4" style={{backgroundColor: isResumido ? corAtivado : corDesativado}}>
                    <Link to="/maparesumido" style={{ textDecoration: 'none'}}> 
                        <button className={isResumido ? "submenu4-button active_button" : "submenu4-button deactive_button"}>{t("links_menu.mapa_resumido")}</button> 
                    </Link>
                </div> 
            </div> 

            <div className='space_nav_abas_'></div> 
        </div>
    );
};

export default TabNav;
import styles from './Retangulo.module.css';

const abreviaUnidades = (valor, t) => {
  let negativo = false;
  if(valor < 0){
    negativo = true;
    valor = valor * -1;
  }
  var valorlocal = valor;
  if(valor >= 1000000 && valor <= 999999999) {
      valorlocal = parseFloat(parseInt(valor) / 1000000).toFixed(3);
      return (negativo ? valorlocal * -1 : valorlocal) + t("dashboard.retangulos.milhoes");
  } else if(valor >= 1000000000 && valor <= 999999999999){
      valorlocal = parseFloat(parseInt(valor) / 1000000000).toFixed(3);
      return (negativo ? valorlocal * -1 : valorlocal) + t("dashboard.retangulos.bilhoes");
  } else return negativo ? valor * -1 : valor;
}

const Retangulo = ({cor, nome, valor, perc, abrevia, t, valor_diferenca}) => {
  return (
    <div style={{backgroundColor:cor}} className={styles.container_retangulo}>
         <div className={styles.nome_variavel_retangulo}>{nome}</div>
         <div className={styles.valor_variavel_retangulo}>{(valor === "NaN" || isNaN(parseFloat(valor))) ? "-" : (Number.isInteger(valor) ? (abrevia ? abreviaUnidades(valor, t) : valor) : parseFloat(valor).toFixed(2))}{perc != null && perc ? "%" : ""}</div>
         <div className={/*styles.seta*/ 'ocultar'}>
            {valor_diferenca > 0 && <div className={styles.triangle_up}></div>}
            {valor_diferenca < 0 && <div className={styles.triangle_down}></div>}
            <div className={styles.seta_valor}>{valor_diferenca > 0 ? "+" + abreviaUnidades(valor_diferenca, t) : abreviaUnidades(valor_diferenca, t)}</div>
         </div>
         

    </div>
  )
}

export default Retangulo
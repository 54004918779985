import { Link } from 'react-router-dom';
import './Footer.css'
import preval from 'preval.macro'
const Footer =  (props) => {
    
    //formate Tue Sep 17 2024 19:22:08 GMT-0300 (Horário Padrão de Brasília) em 17/09/2024 16:22:08
    const dateTimeStamp= new Date(props.date).toLocaleDateString('pt-BR');

    //const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

    return (
        <footer className="page-footer font-small blue pt-4">
            <div className="footer-copyright text-center py-3">
                Atualizado em {dateTimeStamp}
            </div>

        </footer>
    )
};

export default Footer